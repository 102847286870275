import React, { useState, useEffect } from "react";

const authToken =
  "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJSYW5rUGx1cyIsImF1ZCI6IlN0dWRlbnQiLCJpYXQiOjE3Mjc3OTQ2ODAsImV4cCI6MTcyNzg4MTA4MCwidWlkIjoiNDc4MzMiLCJqdGkiOiI2NmZjMGRmODk3YTcxIn0.NKuP6TvfrsgYMQdZiR6o-DSPwbWRcBoOzCRlNMOpQNQ";

const LiveClassesTab = ({ courseId }) => {
  const fetchCourseLiveClasses = async () => {
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      };
      const response = await fetch(
        process.env.REACT_APP_RANK_PLUS_COURSE_DETAILS_LIVE_CLASSES +
          courseId +
          "&limit=10&page=0",
        requestOptions
      );
      const json = await response.json();
      console.log(json);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchCourseLiveClasses();
  }, [courseId]);

  return <div>Live Classes</div>;
};

export default LiveClassesTab;
