import React, { useState, useEffect } from "react";
import PageBanner from "../PageBanner/PageBanner";
import { FREE_LIBRARY_VIDOES_BANNER } from "../../constants/text_constants";
import BANNER_IMG from "../../assets/GirlWithLaptop.png";
import BACKGROUND_IMAGE from "../../assets/BgOverlayImage.jpeg";
import TabHeadings from "./TabHeadings";
import { useDispatch, useSelector } from "react-redux";
import { PAGE_LIMIT } from "../../constants/text_constants";
import { setFreeLibraryVideosCache } from "../../configs/cache";
import LoadingLottie from "../Lottie-Components/LoadingLottie";
import { Card, CardContent } from "../../components/ui/card";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { stripHtml } from "../../utils/util-functions/stripHTML";
import { stripText } from "../../utils/util-functions/stripText";
import StarRatings from "react-star-ratings";
import { formatDate } from "../../utils/util-functions/formatDate";
import { Calendar, Clock } from "lucide-react";
import { Link } from "react-router-dom";

const FreeLibraryVideos = () => {
  const [libraryVideosData, setLibraryVideosData] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const dispatch = useDispatch();
  const cache = useSelector((store) => store.cache.freeLibraryVideosCache);

  const fetchFreeLibraryVidoes = async () => {
    const response = await fetch(
      process.env.REACT_APP_RANK_PLUS_FREE_LIBRARY_VIDEOS + currentPage
    );
    const json = await response.json();
    setTotalPages(Math.ceil(json?.data?.total / PAGE_LIMIT));
    setLibraryVideosData(json?.data);
    dispatch(setFreeLibraryVideosCache([json?.data, currentPage]));
  };

  useEffect(() => {
    if (cache[currentPage]) {
      setLibraryVideosData(cache[currentPage]);
      setTotalPages(Math.ceil(cache[currentPage]?.total / PAGE_LIMIT));
    } else {
      fetchFreeLibraryVidoes();
    }
  }, [currentPage]);

  return (
    <div>
      <PageBanner
        title={FREE_LIBRARY_VIDOES_BANNER?.title1}
        subtitle={FREE_LIBRARY_VIDOES_BANNER?.subtitle}
        rightImgSrc={BANNER_IMG}
        backgroundGradient={
          "linear-gradient(to right, rgba(254, 246, 250, 0.9), rgba(225, 234, 252, 0.9))"
        }
        backgroundImage={BACKGROUND_IMAGE}
        fontColor={"#130C40"}
      />
      <div className="mx-[10rem]">
        <TabHeadings
          index={0}
          text={"Total Videos"}
          total={libraryVideosData?.total}
        />
      </div>
      <div>
        {(!libraryVideosData || libraryVideosData?.vidoes?.length === 0) && (
          <LoadingLottie />
        )}
      </div>
      <div className="mt-10 grid grid-cols-2 mx-[10rem] gap-4">
        {libraryVideosData?.videos.map((ele) => (
          <div key={ele?.id}>
            <Card>
              <CardContent style={{ fontFamily: "DM Sans" }} className="p-0">
                <div className="flex gap-1">
                  <div>
                    <img
                      src={ele?.thumbnail}
                      alt={ele?.title}
                      className="w-[21rem] h-[13.5rem] rounded-lg"
                    />
                  </div>
                  <div className="p-3 flex flex-col">
                    <div className="font-bold">{ele?.title}</div>
                    <div className="text-sm">
                      {stripText(stripHtml(ele?.description))}
                    </div>
                    <div>
                      <StarRatings
                        rating={Math.floor(ele?.rating)}
                        starRatedColor="#FCB129"
                        numberOfStars={5}
                        starDimension="1.2rem"
                      />
                    </div>
                    <div className="italic text-sm my-1">
                      ({ele?.total_rating} Ratings)
                    </div>
                    <div className="text-sm flex items-center gap-2 my-1">
                      <Calendar />
                      {formatDate(ele?.created_at)}
                    </div>
                    <div className="text-sm flex items-center gap-2 mt-1">
                      <Clock />
                      {ele?.video_length}
                    </div>
                    <div className="flex justify-end">
                      <Link to={ele?.url} target="_blank">
                        <button className="border-2 border-[#5A277C] px-2 py-1 rounded-xl hover:bg-[#5A277C] hover:text-white">
                          View
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
        ))}
      </div>
      <div className="p-10 flex items-center justify-center">
        {currentPage > 0 && (
          <ChevronLeft
            className="mx-2 hover:-translate-x-1 transition-all cursor-pointer"
            onClick={() => setCurrentPage((currentPage) => currentPage - 1)}
          />
        )}

        {[
          ...Array(totalPages)
            .keys()
            .map((p) => (
              <span
                className={
                  "mx-2 cursor-pointer" +
                  (p === currentPage ? " font-bold text-lg" : "")
                }
                onClick={() => setCurrentPage((currentPage) => p)}
              >
                {p + 1}
              </span>
            )),
        ]}
        {currentPage < totalPages - 1 && (
          <ChevronRight
            className="mx-2 hover:translate-x-1 transition cursor-pointer"
            onClick={() => setCurrentPage((currentPage) => currentPage + 1)}
          />
        )}
      </div>
    </div>
  );
};

export default FreeLibraryVideos;
