import React, { useState, useEffect } from "react";
import PageBanner from "../PageBanner/PageBanner";
import { FREE_LIBRARY_NOTES_BANNER } from "../../constants/text_constants";
import BANNER_IMG from "../../assets/GirlWithLaptop.png";
import BACKGROUND_IMAGE from "../../assets/BgOverlayImage.jpeg";
import TabHeadings from "./TabHeadings";
import { useDispatch, useSelector } from "react-redux";
import { setFreeLibraryNotesCache } from "../../configs/cache";
import LoadingLottie from "../Lottie-Components/LoadingLottie";
import PDFIcon from "../../assets/pdf.png";
import { Card, CardContent } from "../../components/ui/card";
import { Link } from "react-router-dom";
import { ChevronLeft, ChevronRight } from "lucide-react";

const FreeLibraryNotes = () => {
  const [libraryNotesData, setLibraryNotesData] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const dispatch = useDispatch();
  const cache = useSelector((store) => store.cache.freeLibraryNotesCache);

  const fetchFreeLibraryNotes = async () => {
    const response = await fetch(
      process.env.REACT_APP_RANK_PLUS_FREE_LIBRARY_NOTES + currentPage
    );
    const json = await response.json();
    setTotalPages(Math.ceil(json?.data?.total / 12));
    setLibraryNotesData(json?.data);
    dispatch(setFreeLibraryNotesCache([json?.data, currentPage]));
  };

  useEffect(() => {
    if (cache[currentPage]) {
      setLibraryNotesData(cache[currentPage]);
      setTotalPages(Math.ceil(cache[currentPage]?.total / 12));
    } else {
      fetchFreeLibraryNotes();
    }
  }, [currentPage, cache]);

  return (
    <div>
      <PageBanner
        title={FREE_LIBRARY_NOTES_BANNER?.title1}
        subtitle={FREE_LIBRARY_NOTES_BANNER?.subtitle}
        rightImgSrc={BANNER_IMG}
        backgroundGradient={
          "linear-gradient(to right, rgba(254, 246, 250, 0.9), rgba(225, 234, 252, 0.9))"
        }
        backgroundImage={BACKGROUND_IMAGE}
        fontColor={"#130C40"}
      />
      <div className="mx-[10rem]">
        <TabHeadings
          index={1}
          text={"Total Notes"}
          total={libraryNotesData?.total}
        />
      </div>
      <div>
        {(!libraryNotesData || libraryNotesData?.notes?.length === 0) && (
          <LoadingLottie />
        )}
      </div>
      <div className="grid grid-cols-3 mx-[10rem] gap-2 mt-10">
        {libraryNotesData?.notes.map((ele) => (
          <div key={ele?.id}>
            <Card>
              <CardContent style={{ fontFamily: "DM Sans" }} className="p-2">
                <div className="flex gap-8">
                  <div>
                    <img
                      alt={ele?.title}
                      src={PDFIcon}
                      className="w-[7rem] h-[7rem]"
                    />
                  </div>
                  <div className="flex flex-col justify-between">
                    <div className="text-xl font-bold">{ele?.title}</div>
                    <div>
                      <Link to={ele?.note_pdf} target="_blank">
                        <button className="border-2 border-[#5A277C] px-2 py-1 rounded-xl hover:bg-[#5A277C] hover:text-white">
                          View
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
        ))}
      </div>
      <div className="p-10 flex items-center justify-center">
        {currentPage > 0 && (
          <ChevronLeft
            className="mx-2 hover:-translate-x-1 transition-all cursor-pointer"
            onClick={() => setCurrentPage((currentPage) => currentPage - 1)}
          />
        )}

        {[
          ...Array(totalPages)
            .keys()
            .map((p) => (
              <span
                className={
                  "mx-2 cursor-pointer" +
                  (p === currentPage ? " font-bold text-lg" : "")
                }
                onClick={() => setCurrentPage((currentPage) => p)}
              >
                {p + 1}
              </span>
            )),
        ]}
        {currentPage < totalPages - 1 && (
          <ChevronRight
            className="mx-2 hover:translate-x-1 transition cursor-pointer"
            onClick={() => setCurrentPage((currentPage) => currentPage + 1)}
          />
        )}
      </div>
    </div>
  );
};

export default FreeLibraryNotes;
