import { useState, useEffect } from "react";

const useLandingPageData = () => {
  const [landingPageData, setLandingPageData] = useState(null);

  const fetchLandingPageData = async () => {
    
    const response = await fetch(
      process.env.REACT_APP_RANK_PLUS_LANDING_PAGE_DATA
    );
    const json = await response.json();
    setLandingPageData(json);
  };

  useEffect(() => {
    fetchLandingPageData();
  }, []);

  return landingPageData;
};

export default useLandingPageData;
